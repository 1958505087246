let assetsModule = false;

//let context = require.context('@/overrides/client/assets/', true, /frameworks.js/);
context = require.context(
  "@/",
  true,
  /\/overrides\/client\/assets\/frameworks\.js/
);

context.keys().forEach((key) => {
  if ("./overrides/client/assets/frameworks.js" === key) {
    assetsModule = context(key);
  }
});

if (!assetsModule) {
  assetsModule = require("@/client/assets/frameworks.js");
}

assetsModule = false;

//context = require.context('@/overrides/client/assets/', true, /scss.js/);
context = require.context("@/", true, /\/overrides\/client\/assets\/scss\.js/);

context.keys().forEach((key) => {
  if ("./overrides/client/assets/scss.js" === key) {
    assetsModule = context(key);
  }
});

if (!assetsModule) {
  assetsModule = require("@/client/assets/scss.js");
}
